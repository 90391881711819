import { createBrowserRouter } from "react-router-dom";
import RootLayout from "./layouts/root.tsx";
import Home from "./routes/home.tsx";
import Company from "./routes/company/company.tsx";
import CompanyLayout from "./routes/company/_layout.tsx";
import TourRescheduler from "./components/tourRescheduler.tsx";
import TourCanceling from "./components/tourCanceling.tsx";
import TourPreferences from "./components/tourPreferences.tsx";
import TourScheduler from "./components/tourScheduler.tsx";
import PropertyLayout from "./routes/company/property/_layout.tsx";
import EventLayout from "./routes/company/property/event/_layout.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ":companySlug",
        element: <CompanyLayout />,
        children: [
          {
            element: <Company />,
            index: true,
          },
          {
            path: ":propertySlug",
            element: <PropertyLayout />,
            children: [
              {
                element: <TourScheduler />,
                index: true,
              },
              {
                path: ":eventId",
                element: <EventLayout />,
                children: [
                  {
                    index: true,
                    element: <TourPreferences />,
                  },
                  {
                    path: "reschedule",
                    element: <TourRescheduler />,
                  },
                  {
                    path: "cancel",
                    element: <TourCanceling />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
